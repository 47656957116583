import { createTheme } from '@mui/material/styles';

const primary = {
  dark: '#00CC00',
  main: '#00FF00',
  light: '#66FF66',
};

const secondary = {
  dark: '#CCAC00',
  main: '#FFD700',
  light: '#FFEB66',
};

export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  transparentGrey: '#4a4747cc',
  successful: '#03BB50',
  gray: '#494747',
  primary,
  secondary,
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary,
    secondary,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '0.125rem 0',
          '& .MuiInputBase-input': {},
          '& .MuiFormHelperText-root': {
            width: '100%',
            margin: 0,
          },
          '& fieldset': {
            border: `1px solid ${primary.main}`,
            borderRadius: '0.5rem',
          },
          '& fieldset:hover': {
            border: `1px solid ${primary.dark}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
      },
    },
  },

  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      '"Montserrat"',
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Poppins"',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
